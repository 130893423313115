import { BASE_URL } from '@/env'


class APIService {

    init() {}

    
    async userInfo(params) {
        const path = 'auth/userInfo'
        return await this.baseRequest(path, params)
    }

    async removeFromFavorites(params) {
        const path = 'course/removeFromFavorites'
        return await this.baseRequest(path, params)
    }

    async addToFavorites(params) {
        const path = 'course/addToFavorites'
        return await this.baseRequest(path, params)
    }

    async getAllCategories () {
        const path = 'course/allCategories'
        return await this.baseRequest(path, {})
    }

    async byCategory(params) {
        let path = "course/byCategory"
        return await this.baseRequest(path, params)
    }

    async getMyFavoriteCourses(params) {
        let path = "course/myFavorites"
        return await this.baseRequest(path, params)
    }

    async signUp(params) {
        let path = "auth/webSignUp"
        return await this.baseRequest(path, params)
    }

    async saveCourse(params) {
        let path = "course"
        return await this.baseRequest(path, params)
    }


    async coursePurchased(params) {
        let path = "course/coursePurchased"
        return await this.baseRequest(path, params)
    }
    
    async login(params) {
        let path = "auth/login"
        return await this.baseRequest(path, params)
    }
    
    async getCourseByCategory(params) {
        let path = "course/byCategory"
        return await this.baseRequest(path, params)
    }
    
    async getCourseDetail(params) {
        let path = "course/byId"
        return await this.baseRequest(path, params)
    }

    async getAllCourses() {
        let path = "course/all"
        return await this.baseRequest(path, {}, true)
    }

    async getCategories() {
        let path = "course/allCategories"
        return await this.baseRequest(path)
    }

    
    async baseRequest(path, params = {}, isGET = false) {
        try {
            let header = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            var options = {}
            if (isGET) {
                options = {
                    method: 'GET',
                    headers: header
                }
            } else {
                options = {
                    method: 'POST',
                    headers: header,
                    body: JSON.stringify(params)
                }
            }
           
            console.log('payload: ', options)
            let fullURL = BASE_URL + path
            const response = await fetch(fullURL, options)
            let jsonResp = await response.json()
            console.log('apiservice all response: ', response)
            console.log('apiservice resp: ', jsonResp.resp)
            if (jsonResp.resp === undefined) {
                alert(response.msg)
            }
            return jsonResp.resp
        } catch (err) {
            console.log("error occurred: ", err)
        }
    }
}






export default new APIService()
