<template>
  <div>
    <h1>The pricing on this website is very simple. No forced subscriptions or periodic payments. Simply find the course(s) you’re interested in, pay to enroll, and have access to it on demand. It’s that simple. </h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>