<template>
  <div class="nav-bar">
    <img v-if="isLoggedIn" src="../assets/person.png" @click="handleShowDropdown">
    <button @click="handleLogin" v-else>Register/Log In</button>
        <v-overlay v-model="isDropdown" class="align-top justify-end overLayContainer" opacity="0"  contained>
          <UserProfileDropDownView class="profile-list-dropdown"></UserProfileDropDownView>
      </v-overlay>
  </div>
</template>

<script>

import { UBISHARK_USER_KEY } from '@/env';
import UserProfileDropDownView from './UserProfileDropDownView.vue';

export default {
    components: {
        UserProfileDropDownView
    },
    data() {
        return {
            isLoggedIn: false, 
            currentUser: null, 
            isDropdown: false
        }
    }, 
    mounted() {
        this.currentUser = localStorage.getItem(UBISHARK_USER_KEY)
        this.isLoggedIn = this.currentUser != null
    },
    methods: {
        handleLogin() {
            this.$router.push({path: '/login'}) 
        }, 
        handleShowDropdown() {
            this.isDropdown = true 
        },
        handleCloseOverlay() {

        },
    },
}
</script>

<style lang="scss" scoped>
 .profile-list-dropdown {
    width: 250px;
    height: 450px;
    background-color: white;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    margin: 65px 10px 0 0;
  }
.dropdownContainer {
    width: 200px;
    height: 600px;
    background-color: white;
    border-radius: 8px;
}
.nav-bar {
    display: flex;
    align-items: end;
    justify-content: end;
    width: 150px;
    float: right;
    gap: 15px;
    margin-top: 30px;
    margin-right: 24px;
    button {
        background-color: var(--color-primary);
        border-radius: 8px;
        height: 40px;
        width: 140px;
        color: white;
        border-radius: 4px;
        font-weight: 600;
    }
    img {
        margin-right: 0px;
        border-radius: 15px;
        object-fit: contain;
        height: 30px;
        width: 30px;
        margin-right: 10px;
    }
}

</style>