<template>
  <div class="content-view">
    <div class="nav-bar">
      <img src="../assets/logo.jpg" @click="handleHome">
    </div>
    <div class="product-container">
      <div class="addProductContainer">
        <div class="formContainer">
        <div class="productPhotoContainer">
          <FileDropView class="fileUpload" @handleFileSelected="handleFileSelected" @handleFileURLSelected="handleFileURLSelected"/>
          <img :src="courseFileURL" class="productPhoto">
        </div>
              <div class="inputField">
                <v-text-field
                label="Course Name"
                variant="outlined"
                v-model="course.titleName">
               </v-text-field>
              </div>

              <div class="inputField">
                <v-autocomplete
                  label="Price Tier"
                  :items="pricetiers"
                  item-title="name"
                  item-value="priceTier"
                  v-model="selectedPriceTier"
                ></v-autocomplete>
              </div>
  
              <div class="inputField">
                <v-autocomplete
                  label="Category"
                  :items="courseCategories"
                  item-title="name"
                  item-value="id"
                  v-model="selectedCategory"
                ></v-autocomplete>
              </div>
  
              <div class="inputField">
                <v-textarea
                v-model="course.description"
                label="Description">
                </v-textarea>
              </div>
  
                <div class="priceOptionContainer">
                  <div class="addSectionsContainer">
                    <p>Add Lessons</p>
                    <span class="material-symbols-outlined addProduct" @click="handleAddSection"> add </span>
                  </div>
  
                  <div class="all-options" v-for="(section, index) in options" :key="index">
                    <div class="optionHeaderContainer">
                      <div class="optionHeaderLeftContainer">
                        <span class="material-symbols-outlined"  @click="handleDelete(section)">delete</span>
                        <!-- <input v-model="section.optionName" type="text" :placeholder="`Section ${index + 1}`"> -->
                        <!-- <v-checkbox label="Multi-select" v-model="section.isMultiple"></v-checkbox> -->
                      </div>
                      <span class="material-symbols-outlined addProduct" @click="handleAddOption(index)"> add </span>
                  </div>
                  <div class="optionCell" v-for="lesson in section.items" :key="lesson.id">
                    <input v-model="lesson.titleName" type="text" placeholder="Lesson Name">
                    <v-file-input accept=".mp4, .mov" v-model="lesson.file" label="Lesson File"></v-file-input>
                  </div>
                  </div>
                </div>
  
          </div>
          <div class="save-btns">
            <button v-if="isSave" class="Save buttonload">
              <i class="fa fa-circle-o-notch fa-spin" :class="{'is-btn-enabled': isBtnEnabled()}"></i>Saving...</button>
            <button v-else @click.prevent="handleSave"  v-on:keyup.enter="handleSave" :class="{'is-btn-enabled': isBtnEnabled()}">Save</button>
          </div>
    </div>
  </div>
  </div>
  
  </template>
  
<script>
import APIService from '@/APIService'
import axios from 'axios'
import FileDropView from './FileDropView.vue'
import { BASE_URL } from '@/env'
import { UBISHARK_USER_KEY } from '@/env'
import CryptoJS from 'crypto-js'
  
  export default {
    inject: ["cryptojs"],
    components: {
      FileDropView
    },
    data () {
      return {
        currentUser: null,
        isSave: false,
        options: [],
        pricetiers: [],
        selectedPriceTier: '',
        courseCategories: [],
        selectedCategory: null,
        courseFileURL: '',
        courseFile: null, 
        lessonFile: null, 
        course: {
          priceTier: {}, 
          price: 0, 
          id: Date.now(), 
          category: "", 
          lessons: [], 
          instructorId: '', 
          titleName: ''
        }
      }
    },
    methods: {
      handleHome() {
        this.$router.push({path: "/"})
      },
      isBtnEnabled() {
        const titleName = this.course.titleName
        return this.courseFile != null && titleName.length > 0 && this.selectedPriceTier.length > 0 && this.selectedCategory != null && this.options.length > 0
      },
      handleDelete(section) {
        this.options = this.options.filter(item => item !== section)

      },
      async fetchAllCategories () {
        this.courseCategories = await APIService.getAllCategories()
      },
      handleAddSection () {
        const sectionItem = { optionName: '', isMultiple: false, id: Date.now(), items: [{ titleName: 'Lesson Name', type: '', id: Date.now(), file: null }] }
        this.options.push(sectionItem)
      },
      handleAddOption (section) {
        this.options[section].items.push({ titleName: 'Lesson Name', type: '', id: Date.now(),  file: null })
      },
      handleNavBack () {
        this.$router.go(-1)
      },
      handleFileSelected (selectedFile) {
        this.courseFile = selectedFile
        console.log('selected Course File: ', selectedFile)
      },
      handleFileURLSelected (fileURL) {
        this.courseFileURL = fileURL
      },
      async handleSave () {
        this.isSave = true 
        console.log('Saving...')
        const fullURL = BASE_URL + "course/lesson"
        var lessonIds = []
        for (const index in this.options) {
          const option = this.options[index]
          console.log("lessons: ", option.items)
            await Promise.all(
            option.items.map(async (lesson) => {
              let formData = new FormData();
              const postJson = {
                titleName: lesson.titleName,
                type: "video", 
                id: Date.now()
              }
              formData.append('postJson', JSON.stringify(postJson));
              formData.append('attachment', lesson.file);
              await axios.post(fullURL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              }
            ).then(function(response){
              console.log('SUCCESS!!', response);
              if (response.status == 200 && response.data.resp != null) {
                lessonIds.push(response.data.resp.id)
              } else {
                alert(response.data.msg)
              }
           })
           .catch(function(error){
              console.log('FAILURE!!', error);
           });
          })
         );
        }

        const priceTier = this.pricetiers.find(x => x.priceTier === this.selectedPriceTier)
        console.log('selected priceTier: ', this.selectedPriceTier, 'priceTier: ', priceTier, 'selectedCategory: ', this.selectedCategory)
        this.course.priceTier = priceTier.priceTier
        this.course.price = priceTier.value
        this.course.category = this.selectedCategory
        this.course.lessons = lessonIds
        this.course.instructorId = this.currentUser.id 
        const courseUrl = BASE_URL + "course"
        let formData = new FormData();
          formData.append('postJson', JSON.stringify(this.course));
          formData.append('attachment', this.courseFile);
          await axios.post(courseUrl, formData, {
              headers: {
                    'Content-Type': 'multipart/form-data'
                }
              }
            ).then(function(response){
              console.log('Course SUCCESS!!', response);
              alert(response.data.msg)
           })
           .catch(function(error){
              console.log('Course FAILURE!!', error);
        });
        console.log("lesson ids: ", lessonIds)
        console.log("time to save the course because all files have been saved")
        this.isSave = false
      }
    },
    async mounted () {
      this.pricetiers = [
        {name: "$1", value: 1, id: Date.now(), priceTier: "Tier1"}, 
        {name: "$2", value: 2, id: Date.now(), priceTier: "Tier2"}, 
        {name: "$3", value: 3, id: Date.now(), priceTier: "Tier3"}, 
        {name: "$4", value: 4, id: Date.now(), priceTier: "Tier4"}, 
        {name: "$5", value: 5, id: Date.now(), priceTier: "Tier5"}, 
        {name: "$10", value: 10, id: Date.now(), priceTier: "Tier6"}, 
        {name: "$15", value: 15, id: Date.now(), priceTier: "Tier7"}, 
        {name: "$20", value: 20, id: Date.now(), priceTier: "Tier8"}, 
        {name: "$25", value: 25, id: Date.now(), priceTier: "Tier9"}, 
        {name: "$30", value: 30, id: Date.now(), priceTier: "Tier10"}, 
        {name: "$40", value: 40, id: Date.now(), priceTier: "Tier11"}, 
        {name: "$50", value: 50, id: Date.now(), priceTier: "Tier12"}, 
        {name: "$100", value: 100, id: Date.now(), priceTier: "Tier13"}, 
        {name: "$150", value: 150, id: Date.now(), priceTier: "Tier14"}, 
      ]
      let userCacheString = localStorage.getItem(UBISHARK_USER_KEY)
          if (userCacheString != null) { 
            const userCache = JSON.parse(userCacheString)
            const decryptionToken = userCache.token
            const encryptedUserData = userCache.user
            const decryptedData = CryptoJS.AES.decrypt(encryptedUserData, decryptionToken).toString(CryptoJS.enc.Utf8)
            const cacheInfoObject = JSON.parse(decryptedData)
            this.currentUser = cacheInfoObject
            console.log("userCacheString: ", cacheInfoObject)
            if (this.currentUser == null) {
              this.$router.push({path: "/login"})
            }
      }
       this.fetchAllCategories()
    },
    activated () {
  
    }
  }
  </script>
  
  <style scoped>
  .nav-bar {
    height: 50px;
    width: 100vw;
    background-color: var(--color-primary);
    img {
      width: 34px;
      height: 34px;
      float: left;
      margin-top: 8px;
      margin-left: 50px;
    }
  }
  .save-btns {
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 300px;
    bottom: 40px;
    position: fixed;
    left: calc(50% - 150px);
  }
  .inputField {
    width: 100%;
    max-height: 40px;
    font-weight: 500;
    font-size: 20px;
    margin-right: auto;
    margin-left: auto;
    border: 0px solid var(--color-light-primary) !important;
    border-color: var(--color-light-primary);
    margin-top: 50px;
  }
  
  .product-container {
    width: 800px;
    height: 90vh;
    background-color: lightblue;
    margin-left: auto;
    margin-right: auto;
    overflow-y: hidden;
    button {
      margin-right: auto;
      margin-left: auto;
      width: 300px;
      height: 44px;
      border-radius: 8px;
      color: white;
      background-color: rgb(223, 223, 223);
      cursor: not-allowed;
      &.is-btn-enabled {
        background-color: var(--color-primary);
        cursor: pointer;
      }
    }
  }
  .lightNavBar {
      height: 4rem;
      width: 100vw;
      background-color: white;
      border-bottom: 1px solid var(--color-background);
      float: left;
  }
  
  .lightNavBar h1 {
      font-weight: 700;
      font-size: 20px;
      margin-top: 18px;
      display: flex;
      justify-content: center;
      margin-right: 20px;
  }
  
  .optionCell {
    height: 50px;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .optionCell input {
    width: 200px;
    margin-top: 12px;
  }
  
  .optionHeaderContainer, .addSectionsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    background-color: white;
  }
  .optionHeaderContainer, .optionCell {
    padding: 10px;
  }
  .optionHeaderLeftContainer {
    display: flex;
    span, input {
      margin-top: 15px;
    }
  }
  .addSectionsContainer {
    margin-top: 10px;
    background-color: transparent;
  }
  
  .addProduct {
    font-size: 24px;
    background-color: var(--color-primary);
    border-radius: 4px;
    width: 26px;
    height: 26px;
  }
  
  .priceOptionContainer {
    background-color: var(--color-background);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    border-radius: var(--border-radius-1);
    margin-top: 150px;
    margin-bottom: 20px;
    padding: 10px;
  }
  
  .selectField {
      margin-left: auto;
      margin-right: auto;
      height: 2.5rem;
      width: 90%;
      border-radius: 4px;
      border: 1px solid lightgrey;
  }
  
  .bottomSection {
      margin-top: 8px;
  }
  
  .rightItem {
      margin: 0;
      padding: 0;
      float: right;
      margin-top: -40px;
      margin-right: 10px;
  }
  
  .topNavView {
      height: 4rem;
      width: 100vw;
      background-color: white;
      position: fixed;
      border-bottom: 1px solid var(--color-background);
  }
  
  .topNavView h1 {
      font-weight: 700;
      font-size: 20px;
      margin-top: 18px;
      display: flex;
      justify-content: center;
      margin-right: 20px;
  }
  
  .addProductContentView {
      /* display: grid; */
      /* grid-template-rows: auto 4rem; */
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      margin-top: 4rem;
  }
  
  .addProductContainer {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      background-color: white;
  }
  
  .addPhoto {
      margin-top: 2rem;
      border-color: transparent;
      border: 0 solid transparent;
  }
  
  .productPhotoContainer {
    display: flex;
    flex-direction: row;
    justify-content: start;
    justify-items: start;
    background-color: white;
    .fileUpload {
      width: 150px;
      height: 150ox;
    }
  }
  
  .productPhotoContainer h2 {
      font-size: 18px;
  }
  
  .productPhoto {
    width: 150px;
    height: 150px;
    margin-left: 20px;
    object-fit: cover;
  }
  
  .imageContainer {
      background-color: var(--color-background);
      width: 150px;
      height: 150px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 10px;
      border-radius: var(--border-radius-1);
  }
  
  .formContainer {
      padding-top: 50px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      padding: 50px;
      overflow-y: scroll;
  }
  
  .productDetailContainer {
      width: 100vw;
      height: 95vh;
      background-color: white;
  }
  
  .containerView {
      width: 100vw;
      height: calc(100vh - 4rem);
      background-color: var(--color-background);
      position: relative;
  }
  
  .contentView {
      width: 99%;
      height: 90%;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: 20% 80%;
      overflow-y: scroll;
      background-color: white;
      border-radius: var(--border-radius-1);
      margin-bottom: 20px;
  }
  
    .formContainer input {
      margin-left: 20px;
      margin-right: 20px;
      height: 2.3rem;
      padding-left: 8px;
      border: 1px solid lightgrey;
      border-radius: 4px;
      margin-bottom: 10px;
    }
  
  @media screen and (max-height: 1000px) and (orientation : portrait)  {
    .contentView {
      position: relative;
      width: 99%;
      height: 86%;
      grid-template-columns: 100%;
      overflow-y: scroll;
      margin-top: 1px;
      display: block;
    }
  
    .productPhotoContainer {
      width: 90%;
      height: 280px;
      margin-right: auto;
      margin-left: auto;
      overflow-y: hidden;
      background-color: red;
    }
  
    .imageContainer {
      width: 10rem;
      height: 10rem;
    }
  
    .flexItem {
      width: 98%;
    }
  
    .formContainer {
      width: 100vw;
  }
  
  }
  
  </style>
  