<template>
  <div class="main-couse-container">
    <h3 for="">Courses</h3>
    <div class="course-grid">
        <div class="course-cell" v-for="course in courses" :key="course">
            <img class="course-image" :src="course.thumbnail" @click="handleCourseTapped(course)">
            <span for="" @click="handleCourseTapped(course)">{{ course.category.name }}</span>
            <label for="" @click="handleCourseTapped(course)">{{ course.titleName }}</label>
            <div class="price-favorite-container">
                <p>{{ formatPrice(course.price) }}</p>
                <img :src="require(`../assets/${isFavorited(course.id) ? 'red-heart' : 'favorite-icon'}.png`)" class="favorite-icon" @click="handleFavorite(course.id)">
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import APIService from '@/APIService'
import { UBISHARK_USER_KEY } from '@/env'
import CryptoJS from 'crypto-js'
export default {
    inject: ["cryptojs"],
    name: "courses",
    data() {
        return {
            courses: [], 
            currentUser: null
        }
    }, 
    async mounted() {
        let userCacheString = localStorage.getItem(UBISHARK_USER_KEY)
            if (userCacheString != null) { 
              const userCache = JSON.parse(userCacheString)
              const decryptionToken = userCache.token
              const encryptedUserData = userCache.user
              const decryptedData = CryptoJS.AES.decrypt(encryptedUserData, decryptionToken).toString(CryptoJS.enc.Utf8)
              const cacheInfoObject = JSON.parse(decryptedData)
              this.currentUser = cacheInfoObject
              console.log("userCacheString: ", cacheInfoObject)
              if (this.currentUser != null) {
                await this.getUserInfo()
              }
        }
       await this.getCourses()
    },
    methods: {
        isFavorited(courseId) {
            const favorites = this.currentUser.favorites
            var results = favorites.includes(courseId)
            return results
        },
       async handleFavorite(courseId) {
            const params = {
                courseId: courseId, 
                userId: this.currentUser._id
            }
            const isFavorited = this.isFavorited(courseId)
            if (isFavorited) {
                await APIService.removeFromFavorites(params)
            } else {
                await APIService.addToFavorites(params)
            }
            await this.getUserInfo()
            await this.getCourses()
        },
        handleCourseTapped(course) {
            let path = "/course" 
            this.$router.push(
                {
                    path: path, 
                    query: {
                        id: course._id
                    }
                })
        },
        formatPrice(price) {
            const totalPriceCedis = price * 16
            return price > 0 ? `Gh₵${totalPriceCedis}` : `Free`
        },
        async getCourses() {
           this.courses = await APIService.getAllCourses()
        }, 
        async getUserInfo() {
            const params = {
                userId: this.currentUser._id
            }
           const user = await APIService.userInfo(params)
           console.log('user: ', user)
           this.currentUser = user
           const token = user.id
           let userDataStr = JSON.stringify(user)
           let encyrptedUserData = CryptoJS.AES.encrypt(userDataStr, token).toString()
           let cacheData = {
             token: token, 
             user: encyrptedUserData
           }
        localStorage.removeItem(UBISHARK_USER_KEY)
        localStorage.setItem(UBISHARK_USER_KEY, JSON.stringify(cacheData))
        }
    }
}
</script>

<style scoped lang="scss">
.course-grid {
  display: grid;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  justify-content: center;
  .course-cell {
    display: flex;
    text-align: left;
    flex-direction: column;
    border: 1px solid var(--color-light);
    padding-bottom: 8px;
    .price-favorite-container {
        display: flex;
        justify-content: space-between;
        padding-right: 15px;
    }
    p {
        margin-left: 10px;
        font-weight: 600;
        font-size: 13px;
        margin-top: 4px;
    }
    span {
        padding: 10px;
        color: var(--color-info-dark);
        font-size: 12px;
    }
    label {
        padding: 0 10px 0 10px;
        font-size: 15px;
        font-weight: 600;
        max-height: 44px;
        overflow: hidden;
    }
    .course-image {
        object-fit: cover;
        overflow: hidden;
        height: 180px;
        width: 100%;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }
    .favorite-icon {
        width: 20px;
        height: 20px;
    }
  }
}

.main-couse-container {
    padding-top: 50px;
    h3 {
        margin-bottom: 20px;
        float: left;
        margin-left: 130px;
    }
}

</style>