module.exports = {
    BASE_URL: "https://api.ubishark.com/v1/",
    UBISHARK_USER_KEY: "ubishark_current_user", 
    PAYSTACK_KEY: "pk_live_dcec7c6b97c3f20495c36ae5550f6b4e03ec25db"
};
// "https://api.ubishark.com/v1/"
// https://api.ubishark.com/v1/
// https://api.ubishark.com/v1/course/all
// 

// Live Public key: pk_live_dcec7c6b97c3f20495c36ae5550f6b4e03ec25db      
// Test public key: pk_test_e563562d915b89541f2ec425f96da6e17420a31f