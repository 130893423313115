<template>
  <div class="category-view">
    <div class="cateogry-cell" v-for="category in categories" :key="category.id" :class="categoryType(category)" @click="handleCategoryTapped(category.id)">
        <img src="../assets/bar_chart.png">
        <label for="">{{ category.name }}</label>
    </div>
  </div>
</template>

<script>
import APIService from '@/APIService';
export default {
    data() {
        return {
            categories: []
        }
    }, 
    mounted() {
        this.getCategories()
    },
    methods: {
        categoryType(category) {
            if (category.id === '1728291017') { return 'economics' }
            if (category.id === '1728291090') { return 'graphics' }
            if (category.id === '1728291112') { return 'software' }
            if (category.id === '1728291135') { return 'sports' }
            if (category.id === '1728291063') { return 'environment' }
            return ''
        },
        async getCategories() {
            this.categories = await APIService.getCategories()
        }, 
        handleCategoryTapped(categoryId) {
            this.$router.push({ path: '/category', query: { categoryId: categoryId } })
        }
    }
}
</script>

<style lang="scss" scoped>
.category-view {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: end;
    width: 1000px;
    height: 300px;
    .cateogry-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 14px;
        height: 200px;
        width: 200px;
        border-radius: 4px;
        background-color: var(--color-primary-yellow);
        padding: 0 10px 0 10px;
        &.economics {
            background-color: var(--color-primary);
        }
        &.graphics {
            background-color: var(--color-graphic-green);
        }
        &.software {
            background-color: #D94DA6;
        }
        &.sports {
            background-color: black;
        }
        &.environment {
            background-color: #1DC1D9;
        }
        img {
            width: 44px;
            height: 44px;
        }
        label {
            font-weight: 600;
            font-size: 15px;
            color: white
        }
    }
}
</style>