<template>
  <div class="main-couse-container">
    <h3 for="">Favorite Courses</h3>
    <div class="course-grid">
        <div class="course-cell" v-for="course in courses" :key="course" @click="handleCourseTapped(course)">
            <img :src="course.thumbnail">
            <span for="">{{ course.category.name }}</span>
            <label for="">{{ course.titleName }}</label>
            <p>{{ formatPrice(course.price) }}</p>
        </div>
    </div>
  </div>
</template>

<script>

import APIService from '@/APIService'
  import { UBISHARK_USER_KEY } from '@/env'
  import CryptoJS from 'crypto-js'

export default {
    inject: ["cryptojs"],
    data() {
        return {
            courses: [], 
            currentUser: null
        }
    }, 
    async mounted() {
        let userCacheString = localStorage.getItem(UBISHARK_USER_KEY)
            if (userCacheString != null) { 
              const userCache = JSON.parse(userCacheString)
              const decryptionToken = userCache.token
              const encryptedUserData = userCache.user
              const decryptedData = CryptoJS.AES.decrypt(encryptedUserData, decryptionToken).toString(CryptoJS.enc.Utf8)
              const cacheInfoObject = JSON.parse(decryptedData)
              this.currentUser = cacheInfoObject
              console.log("userCacheString: ", cacheInfoObject)
        }

        if (this.currentUser != null) {
            await this.getMyFavoriteCourses()
        } else {
            this.$router.push({path: "/login"})
        }
    },

    methods: {
        handleCourseTapped(course) {
            let path = "/course" 
            this.$router.push(
                {
                    path: path, 
                    query: {
                        id: course._id
                    }
                })
        },
        formatPrice(price) {
            const totalPriceCedis = price * 16
            return price > 0 ? `Ghc${totalPriceCedis}` : `Free`
        },
        async getMyFavoriteCourses() {
            const param = {
                userId: this.currentUser._id
            }
           this.courses = await APIService.getMyFavoriteCourses(param) // myFavorites
        }
    }
}
</script>

<style scoped lang="scss">
.course-grid {
  display: grid;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  justify-content: center;
  .course-cell {
    display: flex;
    text-align: left;
    flex-direction: column;
    border: 1px solid var(--color-light);
    p {
        margin-left: 10px;
        font-weight: 600;
        font-size: 13px;
        margin-top: 8px;
    }
    span {
        padding: 10px;
        color: var(--color-info-dark);
        font-size: 12px;
    }
    label {
        padding: 0 10px 0 10px;
        font-size: 15px;
        font-weight: 600;
        max-height: 44px;
        overflow: hidden;
    }
    img {
        object-fit: cover;
        overflow: hidden;
        height: 180px;
        width: 100%;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }
  }
}

.main-couse-container {
    padding-top: 50px;
    h3 {
        margin-bottom: 20px;
        float: left;
        margin-left: 130px;
    }
}

</style>