<template>
     <div class="dropFileMainView">
      <div
        class="dropzone-container"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
       >
        <input
          type="file"
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onChange"
          ref="file"
          accept=".jpg ,.jpeg ,.png"
        />

        <label for="fileInput" class="file-label">
          <img src="@/assets/uploadCloudDark.png" class="cloud">
          <div v-if="isDragging">Release to drop a file.</div>
          <div class="uploader" v-else>
            <b>Add Course <br> Thumbnail</b>
          </div>
        </label>
      </div>
    </div>
</template>

<script>
export default {
  props: { card: String },
  data () {
    return {
      isDragging: false,
      isUploading: false,
      isPreviewable: false
    }
  },
  methods: {
    onChange () {
      const file = this.$refs.file.files[0]
      const fileURL = URL.createObjectURL(file)
      this.$emit('handleFileSelected', file)
      this.$emit('handleFileURLSelected', fileURL)
    },
    dragover (e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave () {
      this.isDragging = false
    },
    drop (e) {
      e.preventDefault()
      this.$refs.file.files = e.dataTransfer.files
      this.onChange()
      this.isDragging = false
    },
    generateURL (file) {
      const fileSrc = URL.createObjectURL(file)
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc)
      }, 1000)
      return fileSrc
    }
  },
  watch: {
    remoteFileURL (newVal) {
      if (newVal != null) {
        this.fileURL = null
        this.selectedFile = null
      }
    }
  },
  updated () {
    this.card_id = this.card
  }
}
</script>
<style  lang="scss" scoped>
.cloud {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.fileLinkField {
  width: 280px;
  max-height: 44px;
  font-weight: 500;
  font-size: 20px;
  margin-right: auto;
  margin-left: auto;
  border: 0px solid var(--color-light-primary) !important;
  border-color: var(--color-light-primary);
  margin-top: 5px;
}

.dropFileMainView {
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 150px;
  overflow: hidden;
}

.dropzone-container {
    background: #f7fafc;
    border: 1px solid #e2e8f0;
    height: 150px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-left-radius: var(--border-radius-2);
    border-top-right-radius: var(--border-radius-2);
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.file-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
}
</style>
