<template>
  <div class="course-detail-view">
    <paystack v-if="isPay"
      :amount="amount"
      :email="receiptEmail"
      :paystackkey="paystackkey"
      :reference="reference"
      :callback="callback"
      :close="close"
      :embed="false"
    >
  </paystack>
    <div class="content-view" v-if="course != null">
      <div class="course-detail-center">
        <h1>{{ course.titleName }}</h1>
        <div v-if="course.instructor != null" class="instructor-container">
          <img :src="course.instructor.profilePhoto">
          <div class="title-email-container">
            <label>Instructor</label>
            <label>{{ course.instructor.email }}</label>
          </div>

          <div class="vertical-spacer"></div>

          <div class="title-email-container">
            <label>{{ course.instructor.enrolledStudents.length }}</label>
            <label>Students enrolled</label>
          </div>
        </div>

        <div class="description-curriculum-container">
          <div class="header">
            <label :class="{isActive: isDescriptionTab}" @click="handleDescripTapped">Description</label>
            <label :class="{isActive: !isDescriptionTab}" @click="handleCurriculumTapped">Curriculum</label>
          </div>
          <div class="video-preview-container">
            <video
               class="videoContainer" :src="getVideoFile(course)"
                 :muted="false"
                 :autoplay="false"
                 :controls="hasEnrolled"
                 :loop="false"
                 width="100%"
                 height="100%"
                 :preload="true"
                 :playsinline="true"
                 ref="player"
                :class="{'is-border-cropped': isBorderCropped}"> </video>
         </div>
          <p>{{ course.description }}</p>
        </div>
      </div>
      <div class="course-summary-right">
          <button @click="handleEnroll">ENROLL COURSE</button>
          <label>Course Detail</label>
          <div>
            <div class="summary-cell" v-for="summary in summaries" :key="summary.title">
              <div class="cell-left-content">
                <img src="../assets/playBtn.png">
                <p>{{ summary.title }}</p>
              </div>
              <h4>{{ summary.value }}</h4>
            </div>
          </div>

          <label>Popular Courses</label>
          <div class="popular-courses-container">
            <div class="popular-course-list" v-for="course in popularCourses" :key="course.id">
              <img :src="course.thumbnail">
              <div class="course-cell-info">
                <span class="course-name">{{ course.titleName }}</span>
                <span class="course-email" v-if="course.instructor != null">{{ course.instructor.email }}</span>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from '@/APIService';
import _ from 'lodash';
import CryptoJS from 'crypto-js'
import { UBISHARK_USER_KEY, PAYSTACK_KEY } from '@/env';
import paystack from '@/components/paystack.vue';

export default {
  inject: ["cryptojs"],
  components: {
     paystack
  },
  data() {
    return {
      course: null, 
      summaries: [], 
      isDescriptionTab: true,
      popularCourses: [], 
      currentUser: null, 
      isPay: false, 
      paystackkey: PAYSTACK_KEY, 
      receiptEmail: "receipt@ubishark.com", 
      amount: 0, 
      hasEnrolled: false
    }
  }, 
  computed: {
    reference(){
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for( let i=0; i < 10; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    }
  },
  async mounted() {
    let userCacheString = localStorage.getItem(UBISHARK_USER_KEY)
       if (userCacheString != null && userCacheString.length > 0) {
        let userCache = JSON.parse(userCacheString)
        let decryptionToken = userCache.token
        let encryptedUserData = userCache.user
        let decryptedData = CryptoJS.AES.decrypt(encryptedUserData, decryptionToken).toString(CryptoJS.enc.Utf8)
        let cacheInfoObject = JSON.parse(decryptedData)
        this.currentUser = cacheInfoObject
      }

    const course_Id = this.$route.query.id
    const params = {
      course_Id: course_Id
    }
    // fetch course details
    this.course = await APIService.getCourseDetail(params)
    if (this.currentUser != null) {
      this.hasEnrolled = this.currentUser.myCourses.includes(this.course.id)
    }
    const catParams = {
      category: this.course.category.id
    }
    this.popularCourses = await APIService.getCourseByCategory(catParams)
    this.updateUI()
    this.amount = this.computedAmount()
    this.$forceUpdate()
  }, 
  methods: {
    callback: async function(response){
        this.isPay = false 
        if (response.status === "success" && response.message === "Approved") {
          this.hasEnrolled = true
          // Update enrollment and go home
          const params = {
            courseId: this.course.id,
            userId: this.currentUser._id
          }
         const updatedUserInfo = await APIService.coursePurchased(params)
         const token = updatedUserInfo._id
         let userDataStr = JSON.stringify(updatedUserInfo)
        let encyrptedUserData = CryptoJS.AES.encrypt(userDataStr, token).toString()
        let cacheData = {
            token: token, 
            user: encyrptedUserData
        }
         localStorage.removeItem(UBISHARK_USER_KEY)
         localStorage.setItem(UBISHARK_USER_KEY, JSON.stringify(cacheData))
        } else {
          this.$router.push({path: "/"})
        }
      },
      close: function(){
        this.isPay = false 
    },
    computedAmount() {
      // 16 is the dollar - cedis exchange rate
      return this.course.price * 16 * 100
    },
    handleEnroll() {
      if (this.currentUser == null) {
            this.$router.push({path: '/login'}) 
        } else {
          // Enroll user
          this.isPay = true
        }
    },
    getVideoFile(course) {
      const lesson = _.findLast(course.lessons, function(n) {
         return n.type === 'video';
       });
       return _.get(lesson, 'videoURL')
    },
    handleCurriculumTapped() {
      this.isDescriptionTab = false
    },
    handleDescripTapped() {
      this.isDescriptionTab = true 
    },
    updateUI() {
      this.summaries = [
        {title: "Duration", value: '6 hours'}, 
        {title: "Lectures", value: `${this.course.lessons.length}`}, 
        {title: "Video", value: '4 hours'}, 
        {title: "Quizzes", value: '1'}, 
        {title: "Level", value: 'Advanced'}, 
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.course-detail-view {
  background-color: white;
  height: 100vh;
  width: 100vw;
  .popular-courses-container {
    height: 800px;
    .popular-course-list {
      border-top: 1px solid var(--color-light);
      display: flex;
      align-items: center;
      height: 90px;
      width: 85%;
      gap: 10px;
      margin-left: 20px;
      margin-bottom: 2px;
      img {
        object-fit: contain;
        width: 100px;
        height: 100px;
        overflow: hidden;
      }
      .course-cell-info {
        height: 100%;
        width: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        // align-items: start;
      }
    }
  }
  .description-curriculum-container {
    display: flex;
    flex-direction: column;
    .header {
      display: flex;
      gap: 10px;
      height: 30px;
      margin-top: 20px;
      label {
        font-weight: 600;
        color: var(--color-info-dark);
        &.isActive {
          color: var(--color-info);
          border-bottom: 4px solid var(--color-primary);
        }
      }
    }
    .video-preview-container {
      width: 90%;
      background-color: var(--color-light);
      margin-top: 20px;
    }
    p {
      display: flex;
      font-weight: 500;
      width: 90%;
      color: var(--color-info);
      margin-top: 40px;
      text-align: left;
    }
  }
  padding-top: 60px;
  .content-view {
    display: flex;
    width: 1400px;
    height: 90%;
    margin: auto;
    .course-detail-center {
      height: 100%;
      width: calc(100% - 500px);
    }
    .course-detail-center {
      display: flex;
      flex-direction: column;
      h1 {
        display: flex;
        text-align: left;
        font-weight: 700;
        font-size: 34px;
      }
      .instructor-container {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 10px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 17px;
          object-fit: cover;
          overflow: hidden;
        }
        .title-email-container {
         display: flex;
         flex-direction: column;
         align-items: start;
         gap: 0px;
        }
        .vertical-spacer {
          height: 40px;
          width: 1px;
          background-color: var(--color-light);
          margin: 8px;
        }
      }
    }
    .course-summary-right {
      // display: flex;
      // flex-direction: column;
      width: 500px;
      height: 100%;
      border-left: 1px solid var(--color-light);
      button {
        background-color: var(--color-primary);
        height: 44px;
        width: 400px;
        border-radius: 8px;
        color: white;
        font-weight: 600;
        margin-left: 24px;
      }
      label {
        margin-top: 50px;
        font-weight: 600;
        font-size: 20px;
        display: flex;
        margin-left: 24px;
        margin-bottom: 10px;
      }
    }
    .summary-cell {
      width: 400px;
      height: 50px;
      display: flex;
      margin-left: 24px;
      border-top: 1px solid var(--color-light);
      justify-content: space-between;
      align-items: center;
      img {
        margin-top: 2px;
        height: 20px;
        width: 20px;
      }
      p {
        font-weight: 400;
      }
      .cell-left-content {
        display: flex;
        gap: 10px;
      }
    }
  }

  .course-name, .course-email {
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    max-height: 40px;
    overflow: hidden;
    line-height: 20px;
  }
  .course-name {
    font-weight: 600;
  }
}
</style>