import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../components/Dashboard.vue'
import PrivacyView from '../components/PrivacyView.vue'
import SupportView from '@/components/SupportView.vue'
import HomeView from '@/views/HomeView.vue'
import CourseDetailView from '@/views/CourseDetailView.vue'
import LoginView from '@/views/LoginView.vue'
import SignUpView from '@/views/SignUpView.vue'
import CoursesView from '@/components/CoursesView.vue'
import FavoriteCourses from '@/components/FavoriteCourses.vue'
import CoursebyCategoryView from '@/components/CoursebyCategoryView.vue'
import PlansView from '@/components/PlansView.vue'
import AddCourseView from '@/components/AddCourseView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }, 
  {
    path: '/course/:id?',
    name: 'course',
    component: CourseDetailView
  }, 
  {
    path: '/add',
    name: 'add',
    component: DashboardView
  }, 
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }, 
  {
    path: '/signup',
    name: 'signup',
    component: SignUpView
  }, 
  {
    path: '/courses',
    name: 'courses',
    component: CoursesView
  }, 
  {
    path: '/favorites',
    name: 'favorites',
    component: FavoriteCourses
  }, 
  {
    path: '/plans',
    name: 'plans',
    component: PlansView
  }, 
  {
    path: '/add',
    name: 'add',
    component: AddCourseView
  }, 
  {
    path: '/category',
    name: 'category',
    props: {
      header: true,
      content: true
    },
    component: CoursebyCategoryView
  }, 
  {
    path: '/privacy', 
    name: 'privacy', 
    component: PrivacyView
  }, 
  {
    path: '/support', 
    name: 'support', 
    component: SupportView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

/*
Courses - New page grid of courses
Wishlist - List of course favorited courses / hover to reveal favorite button
My Account dropdown or sign In btn - Profile icon with dropdown { profile, my courses, log out}
Instructor - 60 / 40 split show earnings, total number of enrolled students, Add a course - page to upload the content of their course
Get Started - If logged in, hide Get started else take them to login page
Category - A page with Grid of courses
Add a search icon and show dropdown suggestions
Footer with copyright @2025
Ubishark logo goes back to homepage
*/