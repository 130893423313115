<template>
    <div class="authContentView">
      <div class="inputFieldContainer">
        <img src="../assets/logo.jpg" class="brandLogo">
            <v-text-field type="email" prepend-inner-icon="mdi-email-outline" class="emailField" v-model="email" variant="outlined" label="Email Address"></v-text-field>
            <v-text-field
            :type="showPassword ? 'text' : 'password'"  prepend-inner-icon="mdi-lock-outline" :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-outline'"
             @click:append-inner="viewPassword" class="passwordField"  v-model="password" label="Password" variant="outlined"></v-text-field>  
             <label class="forgotTitleLabel">Forgot password?</label>
              <button v-if="isLogActivated" class="login buttonload">
                  <i class="fa fa-circle-o-notch fa-spin"></i> Logging in... 
              </button>
              <button v-else @click.prevent="handleLogin"  v-on:keyup.enter="handleLogin">Log In</button>
              <label class="forgotTitleLabel" @click.prevent="handleNavToSignUp">Don't have an account? <span>Sign Up</span></label>
      </div>
    </div>
</template>
<script>
import { UBISHARK_USER_KEY } from '@/env';
import CryptoJS from 'crypto-js'
import APIService from '@/APIService';

export default {
  props: {
    isInvite: {
      type: Boolean, 
      default: false
    }
  },
  inject: ["cryptojs"],
  components: {
     
  },
  data() {
    return { 
      email: "", 
      password: "", 
      isLogActivated: false, 
      showPassword: false,
    }
  },
  mounted() {
    APIService.init()
    // TODO: Compute the price from cedis to dollars
  },
  methods: {
      handleNavToSignUp() {
        this.$router.push({path: '/signup'}) 
      },
      viewPassword() {
        this.showPassword = !this.showPassword
      },
      async handleLogin() {
         this.isLogActivated = true 
         var params = {
            email : this.email, 
            password: this.password
          }

        let userInfo = await APIService.login(params)
        if (userInfo != null) {
          userInfo.password = ""
          let token = userInfo._id
          this.encryptAndNavigate(userInfo, token)
        }
      },
      handleSignUpTapped() {
        this.$emit('navToRegister', true)
      }, 
      encryptAndNavigate(gUserInfo, token) {
        let userDataStr = JSON.stringify(gUserInfo)
        let encyrptedUserData = CryptoJS.AES.encrypt(userDataStr, token).toString()
        let cacheData = {
            token: token, 
            user: encyrptedUserData
        }
        localStorage.removeItem(UBISHARK_USER_KEY)
        localStorage.setItem(UBISHARK_USER_KEY, JSON.stringify(cacheData))
        this.$router.push({path: "/"})
     }
    }
}
</script>
<style scoped>
.leftLineView, .rightLineView {
  width: 140px;
  height: 1px;
  background-color: var(--color-light);
  margin-left: 30px;
}

.rightLineView {
  margin-left: 10px;
}

.lineContainer {
  display: flex;
  margin-top: 50px;
}
.brandLogo {
  width: 60px;
  height: 60px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.authContentView {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background);
  width: 100vw;
  height: 100vh;
}
.signUpLabel {
  display: block;
  margin-top: 130px;
}

.signUpSpan {
  color: var(--color-blue);
  font-weight: 600;
}
.inputFieldContainer button { 
  margin-top: 30px;
  width: 340px;
  height: 50px;
  margin: 40px auto 0 auto;
  font-weight: 600;
  font-size: 16px;
  color: white;
  background-color: var(--color-primary);
  border: 0px solid transparent;
  border-radius: var(--border-radius-1);
}

.inputFieldContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 400px;
  height: 500px;
  margin-right: auto;
  margin-left: auto;
  border-radius: var(--border-radius-2);
  margin-top: 50px;
}

.passwordField, .emailField {
  width: 340px;
  max-height: 44px;
  font-weight: 500;
  font-size: 20px;
  margin-right: auto;
  margin-left: auto;
  border: 0px solid var(--color-light-primary) !important;
  border-color: var(--color-light-primary);
}

.emailField {
  margin-top: 54px;
}
.passwordField {
  margin-top: 30px;
}

.forgotTitleLabel {
  font-weight: 600;
  font-size: 14px;
  color: var(--color-dark);
  margin-top: 30px;
  float: left;
  text-align: left;
  margin-left: 34px;
}
.subTitleLabel {
  color: var(--color-light-primary);
  font-weight: 300;
  font-size: 14px;
}

.titleLabel {
  display: block;
  margin-top: 10px;
  font-weight: 500;
  font-size: 18px;
  margin-top: 30px;
}

</style>