<template>
  <div class="home">
    <div class="top-view">
        <div class="top-content-view">
            <NavBar/>
            <div class="top-container">
                <img src="../assets/banner.jpg" @click="handleGoHome">
                <div class="top-right-container">
                    <div v-if="currentUser != null && currentUser.userType == 'student'" class="instructor-enterprise">
                    </div>
                </div>
            </div>
            <h1>OLINE LEARNING IS HERE <br><span>THE WAIT IS OVER</span></h1>
            <button v-if="currentUser == null" class="get-started-btn" @click="handleLogin">GET STARTED?</button>
            <HomeCategoryView class="category-view"/>
        </div>
    </div>
    <div class="top-filter-container">
        <h2>Top Courses</h2>
        <div></div>
    </div>
    <div class="course-grid">
        <div class="course-cell" v-for="course in courses" :key="course">
            <img :src="course.thumbnail" @click="handleCourseTapped(course)">
            <span for="" @click="handleCourseTapped(course)">{{ course.category.name }}</span>
            <label for="" @click="handleCourseTapped(course)">{{ course.titleName }}</label>
            <!-- <p>{{ formatPrice(course.price) }}</p> -->
            <div class="price-favorite-container">
                <p>{{ formatPrice(course.price) }}</p>
                <img :src="require(`../assets/${isFavorited(course.id) ? 'red-heart' : 'favorite-icon'}.png`)" class="favorite-icon" @click="handleFavorite(course.id)">
            </div>
        </div>
    </div>
    <footer>
        <img src="../assets/logo.jpg" alt="">
        <h5>Copyright ©️2025 <b>Ubishark</b></h5>
    </footer>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import HomeCategoryView from '@/components/HomeCategoryView.vue';
import APIService from '@/APIService';
import CryptoJS from 'crypto-js'
import { UBISHARK_USER_KEY } from '@/env';

export default {
    inject: ["cryptojs"],
    components: {
        NavBar, HomeCategoryView
    }, 
    data() {
        return {
            courses: [],  
            currentUser: null
        }
    }, 
    async mounted() {
        let userCacheString = localStorage.getItem(UBISHARK_USER_KEY)
       if (userCacheString != null && userCacheString.length > 0) {
        let userCache = JSON.parse(userCacheString)
        let decryptionToken = userCache.token
        let encryptedUserData = userCache.user
        let decryptedData = CryptoJS.AES.decrypt(encryptedUserData, decryptionToken).toString(CryptoJS.enc.Utf8)
        let cacheInfoObject = JSON.parse(decryptedData)
        this.currentUser = cacheInfoObject
        if (this.currentUser != null) {
           await this.getUserInfo()
        }
      }
      await this.getCourses()
    },
    methods: {
        isFavorited(courseId) {
            if (this.currentUser == null) {
                return false
            } else {
               const favorites = this.currentUser.favorites
               var results = favorites.includes(courseId)
               return results
            }
        },
       async handleFavorite(courseId) {
            const params = {
                courseId: courseId, 
                userId: this.currentUser._id
            }
            const isFavorited = this.isFavorited(courseId)
            if (isFavorited) {
                await APIService.removeFromFavorites(params)
            } else {
                await APIService.addToFavorites(params)
            }
            await this.getUserInfo()
            await this.getCourses()
        },
        handleGoHome() {
            this.$router.push({path: '/'}) 
        },
        handleLogin() {
            this.$router.push({path: '/login'}) 
        },
        handleCourseTapped(course) {
            let path = "/course" 
            this.$router.push(
                {
                    path: path, 
                    query: {
                        id: course._id
                    }
                })
        },
        formatPrice(price) {
            const totalPriceCedis = price * 16
            return price > 0 ? `Ghc${totalPriceCedis}` : `Free`
        },
        async getCourses() {
           this.courses = await APIService.getAllCourses()
        }, 
        async getUserInfo() {
            const params = {
                userId: this.currentUser._id
            }
           const user = await APIService.userInfo(params)
           console.log('user: ', user)
           this.currentUser = user
           const token = user.id
           let userDataStr = JSON.stringify(user)
           let encyrptedUserData = CryptoJS.AES.encrypt(userDataStr, token).toString()
           let cacheData = {
             token: token, 
             user: encyrptedUserData
           }
        localStorage.removeItem(UBISHARK_USER_KEY)
        localStorage.setItem(UBISHARK_USER_KEY, JSON.stringify(cacheData))
        }
    }
}
</script>

<style lang="scss" scoped>
footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    gap: 10px;
    img {
        width: 20px;
        height: 20px;
    }
}
.top-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 1200px;
    margin: auto;
    h2 {
        font-weight: 600;
        font-size: 24px;
    }
    .ilter-container {
        height: 40px;
        width: 180px;
        border: 1px solid var(--color-light);
    }
}
.course-grid {
  display: grid;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  justify-content: center;
  .course-cell {
    display: flex;
    text-align: left;
    flex-direction: column;
    padding-bottom: 8px;
    border: 1px solid var(--color-light);
    .price-favorite-container {
        display: flex;
        justify-content: space-between;
        padding-right: 15px;
    }
    .favorite-icon {
        width: 20px;
        height: 20px;
        margin-top: 5px;
    }
    p {
        margin-left: 10px;
        font-weight: 600;
        font-size: 13px;
        margin-top: 8px;
    }
    span {
        padding: 10px;
        color: var(--color-info-dark);
        font-size: 12px;
    }
    label {
        padding: 0 10px 0 10px;
        font-size: 15px;
        font-weight: 600;
        max-height: 44px;
        overflow: hidden;
    }
    img {
        object-fit: cover;
        overflow: hidden;
        height: 180px;
        width: 100%;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }
  }
}

.course-grid>* {
  height: 300px;
  border-radius: 5px;
}

.home {
    height: 100vh;
    width: 100vw;
    .top-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        img {
            width: 200px;
            height: 100px;
            object-fit: contain;
        }
        .top-right-container {
            display: flex;
            gap: 80px;
            margin-right: 30px;
            .instructor-enterprise, .auth-container  {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
            }
        }
    }
    .top-content-view {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        .category-view {
            margin: 80px auto 0 auto;
        }
        h1 {
            color: var(--color-primary-yellow);
            font-size: 70px;
            margin-top: 20px;
            font-weight: 800;
            span {
                color: white;
            }
        }
        .get-started-btn {
            width: 200px;
            height: 50px;
            font-weight: 600;
            font-size: 14px;
            color: white;
            border: 2px solid white;
            padding: 10px;
            border-radius: 25px;
            margin-top: 20px;
        }
    }
    .top-view {
     background-image: url('https://www.incimages.com/uploaded_files/image/1920x1080/getty_933383882_2000133420009280345_410292.jpg');
     background-size: cover;
     background-position: center center;
     overflow: hidden;
     height: 800px;
     width: 100vw;
   }
}

</style>